// import React, { useEffect } from "react";
// import ReactDOM from "react-dom/client";
// import { PortalProvider } from "./provider/PortalContext";
// import App from "./routes/Routes";

// const root = ReactDOM.createRoot(
//   document.getElementById("root") as HTMLElement
// );

// // Registrar um event listener para receber a mensagem do Service Worker
// if ("serviceWorker" in navigator) {
//   // Registrar um event listener para receber a mensagem do Service Worker
//   navigator.serviceWorker.addEventListener("message", (event) => {
//     if (event.data.type === "NEW_VERSION_AVAILABLE") {
//       // Pedir ao Service Worker para ser atualizado.
//       if (navigator.serviceWorker.controller) {
//         navigator.serviceWorker.controller.postMessage({
//           type: "CHECK_FOR_UPDATES",
//         });
//       }
//     }
//   });
// }

// root.render(
//   <React.StrictMode>
//     <PortalProvider>
//       <App />
//     </PortalProvider>
//   </React.StrictMode>
// );
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { PortalProvider } from "./provider/PortalContext";
import App from "./routes/Routes";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const forceServiceWorkerActivation = async () => {
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.getRegistration();
      if (registration && registration.waiting) {
       
        registration.waiting.postMessage({ type: "ACTIVATE_NEW_SW" });
      }
    } catch (error) {
      console.error("Erro ao forçar a ativação do Service Worker:", error);
    }
  }
};

forceServiceWorkerActivation();

root.render(
 // <React.StrictMode>
    <PortalProvider>
      <App />
    </PortalProvider>
 // </React.StrictMode>
);